import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }

    /* Ensure that all elements use border-box sizing to prevent overflow from padding or borders */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
        color: #000;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    body {
        background: #fff;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        font-family: 'Motiva Sans Light', sans-serif;
        border-radius: 4px;
        border: 1px solid #ddd; 
        background: rgb(241, 242, 243);
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
        box-shadow: 0 10px 20px rgb(23 31 114 / 4%);
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

        :focus {
            background: none;
            border-color: #FD8B04; 
            box-shadow: 0 10px 20px rgb(23 31 114 / 8%);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #000;
        font-size: 46px;
        line-height: 1.18;
        margin: 0;

        @media only screen and (max-width: 768px) {
            font-size: 47px;
        }
      
        @media only screen and (max-width: 575px) {
            font-size: 36px;
        }
    }

    p {
        color: #4F4F4F;
        font-size: 24px;
        line-height: 1.6;
        font-weight: 600;

        @media only screen and (max-width: 1024px) {
            font-size: 18px;
        }

        @media only screen and (max-width: 768px) {
            font-size: 16px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 15px;
        }
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }

    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    /* Adjust drawer to prevent overflow */
    .ant-drawer-content-wrapper {
        width: 100% !important;
        max-width: 600px;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }
`;
