import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import Router from "./router";
import i18n from "./translation";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

// Use createRoot instead of ReactDOM.render
const container = document.getElementById("root");
const root = createRoot(container!); // Create root using the root element
root.render(<App />); // Render the app with the new API
