import { Container, ComingSoonWrapper } from "./styles";


const Block = () => {
    return (
        <Container >
            <ComingSoonWrapper>
                Coming Soon!
            </ComingSoonWrapper>
        </Container>
    );
};

export default Block;
