import styled from 'styled-components';

export const StyledButton = styled.button`
  background: #fe7624;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  width: 80%;
  height: 45px;
  border: none;
  border-radius: 8px;
  padding: 13px 0;
  cursor: pointer;
  max-width: 180px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 15%);

  &:hover {
    background-color: #fd8b04; /* Darker orange on hover */
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  /* Fix height for anchor elements */
  &[href] {
    height: 50px;
    line-height: 50px; /* Ensure content is vertically centered */
    text-decoration: none;
    padding: 0; /* Remove padding, as line-height centers the content */
  }
`;
