import styled, { keyframes } from 'styled-components';

const flowBackground = keyframes`
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 100%; }
  100% { background-position: 0% 0%; }
`;

export const ComingSoonWrapper = styled.div`
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #fcb045, #fd1d1d, #833ab4);
  background-size: 400% 400%;
  animation: ${flowBackground} 8s ease infinite;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  @media (max-width: 768px) {
    height: 60px;
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    height: 50px;
    font-size: 1.2rem;
  }
`;

export const Container = styled.div`
  width: 100vw;
  text-align: center;
  margin: 0;
  padding: 0;
`;
