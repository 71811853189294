import { StyledButton } from "./styles";
import {
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  ReactNode,
  FC,
} from "react";

interface BaseButtonProps {
  color?: string;
  children: ReactNode;
}

type AnchorButtonProps = BaseButtonProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> & {
    href: string;
    target?: string;
    as?: "a";
  };

type RegularButtonProps = BaseButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
    type?: "button" | "submit" | "reset";
    as?: "button";
  };

type ButtonProps = AnchorButtonProps | RegularButtonProps;

export const Button: FC<ButtonProps> = (props) => {
  const { color, children, ...rest } = props;

  if ("href" in rest) {
    // Render as anchor tag
    return (
      <StyledButton as="a" color={color} {...(rest as AnchorButtonProps)}>
        {children}
      </StyledButton>
    );
  } else {
    // Render as button
    return (
      <StyledButton as="button" color={color} {...(rest as RegularButtonProps)}>
        {children}
      </StyledButton>
    );
  }
};
